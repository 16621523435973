/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useReducer } from "react";
import {
  formValueSelector,
  reduxForm,
  Field,
  FieldArray,
} from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { createAction } from "@reduxjs/toolkit";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  Label,
  LocationSelectModal,
  RadioButton,
  Col,
  Hr,
  Row,
  Avatar,
  ErrorText,
  InputSelect,
  DefaultSelect,
  DesktopBreakpoint,
  PhoneBreakpoint,
} from "@d-lighted/design-system";

import { sortBy } from "lodash";
import { put, useGet } from "utils/api";
import { actions } from "redux/actions";
import { apiUtils } from "utils/apiUtils";
import {
  isProductSchedulingOrganization,
  generateHelpUrl,
  isGaroonIntegrated,
  getSubdomain,
  getScheduler,
} from "utils/userUtils";
import { allowedMeetingToolsReducer } from "./reducer";
import { Dashboard } from "../../../components/layouts/Dashboard";
import MemberSelection from "../../../components/forms/MemberSelection/MemberSelection";
import RoomSelection from "../../../components/forms/ResourceSelection";
import google_icon from "../../../assets/img/g_meet_icon.svg";
import microsoft_icon from "../../../assets/img/ms_teams_icon.svg";
import zoom_icon from "../../../assets/img/zoom.svg";
import webex_icon from "../../../assets/img/ico_webex.svg";
import { useValidation } from "../../../utils/validation";
import {
  getSelectedDuration,
  setSelectedDuration,
} from "views/home/utils/utils";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../i18n/Meetings";
import { isReceptionEnabled, isEnterprisePlan, isTemplatesEnabled } from "utils/userUtils";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import PageTypeSwitch from "components/elements/PageTypeSwitch";
import Tooltip from "components/elements/Tooltip";
import ToolWarning from "components/elements/ToolWarning";
import userDefault from "assets/img/userDefault.png";
import {
  calendarIntegratedUser,
  zoomNotAllowedUser,
} from "components/forms/MemberSelection/utils";
import { useBuildings } from "utils/conferenceRoomUtils";
import { pushToGA, events } from "utils/gtmUtils";
import { useLocale } from "utils/localeUtils";
import { isMobileDevice } from "utils/mobileViewChecker";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import * as messageIdConstants from "components/layouts/constants/messageIdConstants"
import PlanIcon from '../../../components/elements/PlanIcon';
import CalendarEventCustomization from "../components/CalendarEventCustomization";

const Logo = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 5px;
  position: relative;
  top: 7px;
`;

const WebexLogo = styled(Logo)`
  height: 20px;
  margin: 4px 5px 4px 0;
`;

const Text = styled(Label)`
  font-size: 14px;
  font-weight: 200;
  top: 3px;
  position: relative;
`;

const InlineWrapper = styled.div`
  display: inline-block;
  margin-right: ${(props) => (props.mr ? props.mr : "0")}px;
  margin-left: ${(props) => (props.ml ? props.ml : "0")}px;
  color: #314143;
  font-weight: 200;
`;

const StyledErrorText = styled(ErrorText)`
  @media (max-width: ${MOBILEBREAKPOINT}){
    white-space: normal;
  }
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

function newUnsavedMembersList(state = {}, action) {
  return { ...allowedMeetingToolsReducer(state, action.membersList) };
}

function newUnsavedWebConfType(state = {}, action) {
  state.type = action.type;
  return { ...state };
}

const Meetings = (props) => {
  const {
    history,
    pristine,
    submitting,
    intl,
    useNotify,
    meetingType,
    durations,
    permissions,
    initialize,
    handleSubmit,
  } = props;
  const params = useParams();
  const { uid, settings } = params;
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  const [locale] = useLocale();
  const [securityLevelOptions, setSecurityLevelOptions] = useState([]);
  useGet(calendarSettingRequest(params.uid));
  useGet(actions.getSettingGates);
  const dispatch = useDispatch();
  const updateCalendarSetting = createAction(
    `${actions.getCalendarSetting.name}_update`
  );
  const { data, isSuccess, hasError } = useSelector(
    (state) => state.calendarSetting
  );
  const { data: settingGatesData, isSuccess: isSettingGatesSuccess } = useSelector(state => state.settingGates);
  const { bookingCalendarAttendeesLimit, templateBookingCalendarsCount } = data;
  const [pressed, setPressed] = useState(data?.pageType || false);
  const [pageType, setPageType] = useState(data?.pageType || false);
  const [allowedAttendeesMeetingTools, setAllowedMeetingTools] = useReducer(
    newUnsavedMembersList,
    {
      google: false,
      microsoft: false,
      zoom: false,
    }
  );
  const [checkedWebConfType, setWebConfType] = useReducer(
    newUnsavedWebConfType,
    { type: "" }
  );
  const notify = useNotify(intl);
  const meetingToolAvailable = Object.values(allowedAttendeesMeetingTools).some(
    (value) => value === true
  );
  const [selectedBuilding, setSelectedBuilding] = useState(
    (JSON.parse(localStorage.getItem("building_selection")) || {})[uid]
  );
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  const { conferenceRoomLimit, required, durationsLimit } = useValidation(intl);
  const [buildings, isLoading, getBuildings] = useBuildings(uid);

  const slotDurationOptions = [];
  [15, 20, 30, 60, 90].forEach((i) => {
    slotDurationOptions.push({
      value: i,
      label: `${intl.formatMessage({ ...messages[`d${i}`] })}`,
    });
  });

  const isMobileView = isMobileDevice();

  useEffect(() => {
    setPressed(data?.pageType);
    setPageType(data?.pageType);
    if (isSuccess) {
      const {
        meetingType,
        durations,
        attendanceRule,
        members,
        webConferenceType,
        conferenceRooms,
        slotDuration: slot,
        optionalAttendeesNumber,
        optionalAttendeesSelectionRule,
        bookingCalendarTemplateUid,
        permissions,
        selectedSecurityLevel,
        calendarEventCustomizations,
      } = data;

      const slotDuration =
        slot != null
          ? {
              value: slot,
              label: `${intl.formatMessage({ ...messages[`d${slot}`] })}`,
            }
          : null;
      const sortedConferenceRooms = sortBy(conferenceRooms, ["rowOrderRank"]);
      const optionAttendeeOptions = {
        value: optionalAttendeesSelectionRule || "priority_order",
        label: optionalAttendeesSelectionRule === "priority_order" ? `${intl.formatMessage({ ...messages.priorityOrder})}` : `${intl.formatMessage({ ...messages.randomOrder })}` 
      };
      const numberOptionalAttendees = {
        value: optionalAttendeesNumber,
        label: `${optionalAttendeesNumber}`
      }
      setTemplateUid(bookingCalendarTemplateUid);
      const selectedLevelValue = selectedSecurityLevel ? { value: selectedSecurityLevel.level, label: selectedSecurityLevel.label } : {};
      initialize({
        meetingType: meetingType,
        durations: [...durations],
        attendanceRule,
        members,
        webConferenceType,
        conferenceRooms: sortedConferenceRooms,
        slotDuration,
        optionalAttendeesNumber: numberOptionalAttendees,
        optionAttendeeOptions,
        permissions: convertToOption(permissions, intl),
        securityLevel: selectedLevelValue,
        visibility: calendarEventCustomizations?.visibility || "public",
        title: calendarEventCustomizations?.title,
      });
      onAttendeesChange(members);
      setWebConfType({ type: webConferenceType });
    }
    if (hasError) {
      dispatch({
        type: "calendarSetting_get",
        payload: {
          status: null,
          data: null,
          isSuccess: false,
          hasError: false,
          initial: true,
        },
      });
      history.push("/");
    }
  }, [data, locale]);

  useEffect(() => {
    if(isSettingGatesSuccess) {
      setSecurityLevelOptions((settingGatesData?.securityLevels || [])
        .map((securityLevel) => {
          return { label: securityLevel.label, value: securityLevel.level }
        }));
    }
  }, [settingGatesData, data]);

  if (!isSuccess) {
    return <Redirect to="/" />;
  }

  const onAttendeesChange = (membersList) => {
    setAllowedMeetingTools({ membersList: membersList });
  };

  function mapMembers(values) {
    return values.members.map((member) => ({
      uid: member.uid,
      employee_uqid: member.employeeUqid,
      must_attend: member.mustAttend || false,
      editor: member.editor,
      attendee: member.attendee,
    }));
  }

  function updateBuildingStorage(item) {
    const buildingSelection =
      JSON.parse(localStorage.getItem("building_selection")) || {};
    buildingSelection[uid] = item;
    localStorage.setItem(
      "building_selection",
      JSON.stringify(buildingSelection)
    );
  }

  function formatBody(values, members) {
    let body = {
      meeting_type: values.meetingType,
      durations: [...values.durations].sort((a, b) => a - b),
      attendance_rule: values.attendanceRule,
      conference_rooms: values.conferenceRooms,
      members: members,
      slot_duration: values.slotDuration.value,
      optional_attendees_number: values.optionalAttendeesNumber.value,
      optional_attendees_selection_rule: values.optionAttendeeOptions.value,
      security_level: values.securityLevel ? values.securityLevel.value : null,
      calendar_event_customizations: { title: values.title, visibility: values.visibility }
    };

    if (body["meeting_type"]) {
      body["web_conference_type"] = values.webConferenceType;
    }
    return body;
  }

  function processResult(result, body) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message || result?.data?.error?.message);
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      const selectedDuration = parseInt(
        getSelectedDuration(uid, body.durations) || 0,
        10
      );
      
      if (!body.durations.includes(selectedDuration)) {
        if (!body.durations.includes(60)) {
          setSelectedDuration(uid, body.durations[0]);
        } else {
          setSelectedDuration(uid, 60);
        }
      }
      dispatch(updateCalendarSetting(apiUtils.formatAxiosResponse(result)));
      return result;
    }
  }

  const onSubmit = (values) => {
    if(templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    let url = `/booking_calendar_settings/${uid}`;
    let members = mapMembers(values);
    let body = formatBody(values, members);
    if (values.durations.length === 0) {
      notify.setError(messages.notifyError, messages.notifyDurations);
      return;
    }
    const allAttendees = values.members.filter(member => member.attendee || member.mustAttend)
    if(bookingCalendarAttendeesLimit && allAttendees.length > bookingCalendarAttendeesLimit) {
      const message = values.attendanceRule === "custom_will_attend" ? "attendeesLimitCustom": "attendeesLimitDefault";
      notify.setError(messages.notifyError, intl.formatMessage({...messages[message]}, { count: bookingCalendarAttendeesLimit }));
      return;
    }
    // temporary validation
    if (values.durations.length > 4) {
      notify.setError(messages.notifyError, messages.durationsLimit);
      return;
    }
    if (
      values.meetingType === "web_conference" &&
      values.webConferenceType === "not_web_conference"
    ) {
      notify.setError(messages.notifyError, messages.notifyWebConferenceType);
      return;
    }
    if (
      !isTemplatePage &&
      members.reduce(
        (accumulated, current) => accumulated || current.attendee,
        false
      ) === false
    ) {
      notify.setError(messages.notifyError, messages.notifyMembers);
      return;
    }

    let result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result, body);
  };

  const validateDurations = (durations) => {
    const error = durationsLimit(durations);
    return error != null ? <StyledErrorText>{error}</StyledErrorText> : null;
  };

  const customAttendanceRuleBlocked = () => {
    if(!isEnterprisePlan()) {
      return messageIdConstants.customAttendanceRuleBlocked;
    }
    return null;
  }
  
  const isMeetingTypeAdministrator = permissions?.meetingType?.value === "administrator";
  
  const webConferences = [
    {
      name: 'google',
      logoComponent: Logo,
      logo: google_icon,
      alt: "Google Meet",
      validation: allowedAttendeesMeetingTools.google,
    },
    {
      name: 'microsoft',
      logoComponent: Logo,
      logo: microsoft_icon,
      alt: "Microsoft Teams",
      validation: allowedAttendeesMeetingTools.microsoft,
    },
    {
      name: 'zoom',
      logoComponent: Logo,
      logo: zoom_icon,
      alt: "Zoom",
      validation: allowedAttendeesMeetingTools.zoom,
    },
    {
      name: 'webex',
      logoComponent: WebexLogo,
      logo: webex_icon,
      alt: "Webex",
      validation: allowedAttendeesMeetingTools.webex,
    }
  ]

  return (
    <Dashboard>
      <form>
        <Row mt={["26px", "40px"]} mb="26px">
          <Col xs>
            <TemplateBlocked
              blocked={permissions?.meetingType?.value}
              header={
                <Row mb="10px">
                  <Col xs>
                    <Label>
                      <FormattedMessage {...messages.meetingType} />
                    </Label>
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="meetingType" />
                    </Col>
                  )}
                </Row>
              }
              fields={
                <>
                  {(isReceptionEnabled() && !isProductSchedulingOrganization()) && (
                    <Row>
                      <Col xs>
                        <Field
                          id="meetingType"
                          name="meetingType"
                          trueFor="coming_to_visit"
                          component={RadioButton}
                        >
                          <FormattedMessage {...messages.comingToVisit} />
                        </Field>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs>
                      <Field
                        id="meetingType"
                        name="meetingType"
                        trueFor="web_conference"
                        component={RadioButton}
                      >
                        <FormattedMessage {...messages.webConference} />
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Field
                        id="meetingType"
                        name="meetingType"
                        trueFor="going_to_visit"
                        component={RadioButton}
                      >
                        <FormattedMessage {...messages.goingToVisit} />
                      </Field>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        {(meetingType || []).includes("web_conference") && (
          <TemplateBlocked
            blocked={permissions?.meetingType?.value}
            header={
              <Row mb="10px">
                <Col xs display="flex" alignItems="center">
                  <Label>
                    <FormattedMessage {...messages.provider} />
                  </Label>
                </Col>
              </Row>
            }
            fields={
              <Row mb="26px">
                <Col>
                  {!isTemplatePage && !meetingToolAvailable && (
                    <Label fontSize="14px">
                      <Row mb="10px">
                        <StyledErrorText>
                          <FormattedMessage
                            {...messages.meetingToolNotIntegrated}
                            values={{
                              br: <br/>,
                              a: (chunks) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    `${window.location.protocol}//${getSubdomain()}${process.env.REACT_APP_WEB_PATH}/info/integrations`
                                  }
                                >
                                  {chunks}
                                </a>
                              ),
                            }}
                          />
                        </StyledErrorText>
                      </Row>
                    </Label>
                  )}
                  {webConferences.map(({ validation, name, logoComponent: LogoComponent, logo, alt }) => {
                    const conferenceProviderRow = (
                      <Row>
                        <Col xs>
                          <Field
                            id="webConferenceType"
                            name="webConferenceType"
                            trueFor={name}
                            component={RadioButton}
                            onClick={() => setWebConfType({ type: name })}
                          >
                            <LogoComponent src={logo} alt={alt}/>{" "}
                            <FormattedMessage {...messages[name]} />
                          </Field>
                        </Col>
                      </Row>
                    );
                    
                    // Forces show when we are being managed by administrator
                    if (!isTemplatePage && isMeetingTypeAdministrator) {
                      return checkedWebConfType?.type === name ? conferenceProviderRow : null;
                    }  
                      
                    //  This prevents for showing the ones that should not show in normal flow
                    let scheduler = getScheduler();
                    if (scheduler === 'outlook') scheduler = 'microsoft';
                    const templateSchedulerValidation = isTemplatePage && [scheduler, "zoom", "webex"].includes(name);
                    if (!(templateSchedulerValidation || validation)) return null;
                    
                    return conferenceProviderRow;
                    }
                  )}
                </Col>
              </Row>
            }
            />
        )}
        <Row mb="26px">
          <Col xs>
            <TemplateBlocked 
              blocked={permissions?.durations?.value}
              header={
                <Row>
                  <Col xs>
                    <Label>
                      <FormattedMessage {...messages.durations} />
                      <Tooltip
                        header={intl.formatMessage({ ...messages.durations })}
                        message={intl.formatMessage(
                          { ...messages.durationsTooltip },
                          {
                            br: <br />,
                          }
                        )}
                        width={340}
                        helpPath="how-to-create-pages/#time1"
                      />
                    </Label>
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="durations" />
                    </Col>
                  )}
                </Row>
              }
              fields={
                <>
                  <Row mt="10px">
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={15}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d15} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={20}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d20} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={30}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d30} />
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={40}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d40} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={45}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d45} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field
                        id="timeFrame2"
                        name="durations"
                        trueFor={60}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d60} />
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <Field
                        id="timeFrame3"
                        name="durations"
                        trueFor={90}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d90} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field name="durations" trueFor={120} component={Checkbox}>
                        <FormattedMessage {...messages.d120} />
                      </Field>
                    </Col>
                    <Col xs>
                      <Field
                        id="timeFrame"
                        name="durations"
                        trueFor={180}
                        component={Checkbox}
                      >
                        <FormattedMessage {...messages.d180} />
                      </Field>
                    </Col>
                  </Row>
                </>
              }
            />
            {validateDurations(durations)}
            {/* This should be uncommented in a future issue when design is done */}
            {/*<Row>*/}
            {/*  <Col xs>*/}
            {/*    <FieldArray*/}
            {/*      name="durations"*/}
            {/*      component={DurationSelection}*/}
            {/*      validate={[durationsLimit]}*/}
            {/*    />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Col>
        </Row>
        <Row mb="26px">
          <Col xs>
            <TemplateBlocked
              blocked={permissions?.durations?.value}
              header={
                <Row mb="10px">
                  <Col xs>
                    <Label>
                      <FormattedMessage {...messages.slotDuration} />
                      <Tooltip
                        header={intl.formatMessage({ ...messages.slotDuration })}
                        message={intl.formatMessage(
                          { ...messages.slotDurationTooltip },
                          {
                            br: <br/>,
                          }
                        )}
                        width={340}
                        helpPath="how-to-create-pages/#time2"
                      />
                    </Label>
                  </Col>
                </Row>
              }
              fields={
                <Row mt="12px">
                  <Col xs>
                    <DesktopBreakpoint>
                      <Field
                        name="slotDuration"
                        component={InputSelect}
                        height="44px"
                        width="114px"
                        options={slotDurationOptions}
                        validate={[required]}
                      />
                    </DesktopBreakpoint>
                    <PhoneBreakpoint>
                      <Field
                        name="slotDuration"
                        id="slotDuration"
                        component={DefaultSelect}
                        options={slotDurationOptions}
                      />
                    </PhoneBreakpoint>
                  </Col>
                </Row>
              }
              />
          </Col>
        </Row>

        <PlanBlocked plan="enterprise">
          <CalendarEventCustomization
            isTemplatePage={isTemplatePage}
            {...props} 
          />
        </PlanBlocked>
        <Row mb="26px">
          <Col xs>
            <TemplateBlocked
              blocked={permissions?.conferenceRooms?.value}
              header={
                <Row mb="12px">
                  <Col xs>
                    <Label>
                      <FormattedMessage {...messages.conferenceRoom} />
                    </Label>
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="conferenceRooms"/>
                    </Col>
                  )}
                </Row>
              }
              fields={
                <>
                  <Row mb="12px">
                    <Col xs>
                      <Label color="#314143">
                        <FormattedMessage {...messages.building} />：
                      </Label>
                      <Label
                        isCursor
                        color="#00bbb5"
                        onClick={(e) => {
                          getBuildings();
                          toggleModal(e);
                        }}
                      >
                        {selectedBuilding?.label ||
                          intl.formatMessage({ ...messages.unselected })}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs>
                      <FieldArray
                        name="conferenceRooms"
                        component={RoomSelection}
                        validate={[conferenceRoomLimit]}
                        building={selectedBuilding?.value}
                      />
                    </Col>
                  </Row>
                </>
              }
              />
          </Col>
        </Row>

        <Row>
          <Col xs>
            <Row>
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.members} />
                </Label>
              </Col>
            </Row>
            <Row mb="10px">
              <Col xs>
                <InlineWrapper mr="11">
                  <Text primary>
                    <FormattedMessage {...messages.team} />
                  </Text>
                </InlineWrapper>
                <PageTypeSwitch
                  alias={data.alias}
                  pageType={data?.pageType}
                  setPageType={setPageType}
                  useNotify={useNotify}
                  setPressed={setPressed}
                  disabled={isTemplatesEnabled()}
                />
                <Tooltip
                  message={intl.formatMessage({
                    ...messages.pageTypeTooltip,
                  })}
                  width={200}
                  top={8}
                  onHover={(duration) =>
                    pushToGA({
                      ...events.mouseover_featureTooltip("team", duration),
                    })
                  }
                />
              </Col>
            </Row>

            {!pressed && (
              <Row mb="26px">
                <Col xs={7} mr="-10px">
                  <Row>
                    <Col xs display="flex" justifyContent="left">
                      <Avatar src={userDefault} mr="12px" />
                      <Text primary>{data.members[0].name}</Text>
                      {!calendarIntegratedUser(
                        data.members[0].authorizedSchedulers
                      ) && (
                        <ToolWarning
                          message={intl.formatMessage(
                            { ...messages.calendarNotIntegrated },
                            {
                              a: (chunks) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={generateHelpUrl(
                                    "/member-integration-error"
                                  )}
                                  onClick={() =>
                                    pushToGA({
                                      ...events.click_link_help(
                                        "/member-integration-error"
                                      ),
                                    })
                                  }
                                >
                                  {chunks}
                                </a>
                              ),
                            }
                          )}
                          top={3}
                        />
                      )}
                      {zoomNotAllowedUser(
                        data.members[0].authorizedSchedulers,
                        meetingType,
                        checkedWebConfType.type
                      ) && (
                        <ToolWarning
                          message={intl.formatMessage({
                            ...messages.zoomNotIntegrated,
                          })}
                          top={3}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        {pressed && (
          <PlanBlocked plan="standard">
            <Row>
              <Col xs>
                <TemplateBlocked
                  blocked={permissions?.attendanceRule?.value}
                  header={<Row mb="10px">
                    <Col xs>
                      <Label>
                        <FormattedMessage {...messages.attendanceRule} />
                      </Label>
                    </Col>
                    {isTemplatePage && (
                      <Col>
                        <TemplatePermissionSelector name="attendanceRule"/>
                      </Col>
                    )}
                  </Row>}
                  fields={
                    <>
                      <Row mt="12px">
                        <Col>
                          <Field
                            id="attendance"
                            name="attendanceRule"
                            trueFor="all_will_attend"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.allWillAttend} />
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            id="attendance2"
                            name="attendanceRule"
                            trueFor="one_will_attend"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.oneWillAttend} />
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            id="attendance3"
                            name="attendanceRule"
                            trueFor="random_one_will_attend"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.randomOneWillAttend} />
                          </Field>
                        </Col>
                      </Row>
                      <PlanBlocked
                        mt={isEnterprisePlan() ? "0px": "13px"}
                        plan="enterprise"
                        messageId={customAttendanceRuleBlocked()}
                        showDefaultMessage={false}
                      >
                        <Row>
                          <Col display="flex">
                            <Field
                              id="attendance4"
                              name="attendanceRule"
                              trueFor="custom_will_attend"
                              component={RadioButton}
                            >
                              <FormattedMessage {...messages.customWillAttend} />
                            </Field>
                            <PlanIcon
                              featurePlan="enterprise"
                              ml={-12}
                              mb={8}
                              width={260}
                            />
                          </Col>
                        </Row>
                      </PlanBlocked>
                    </>
                  }
                />

              </Col>
            </Row>
            <Row mt="26px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.members} />
                </Label>
              </Col>
            </Row>
            {isTemplatePage ? (
              <Row mt="10px">
                <Col>
                  <Label fontSize="13px">
                    <FormattedMessage {...messages.membersInTemplate} />
                  </Label>
                </Col>
              </Row>
            )
              :
            (
              <Row mb="26px">
                <Col xs>
                  <FieldArray
                    form={"MemberSelection"}
                    name={"members"}
                    members={data?.members}
                    component={MemberSelection}
                    pageType={pageType}
                    handleAttendeesChange={onAttendeesChange}
                    webConferenceType={checkedWebConfType}
                    attendeesLimit={bookingCalendarAttendeesLimit}
                    meetingType={meetingType}
                    useNotify={useNotify}
                    alias={data?.alias}
                    isGaroonIntegrated={isGaroonIntegrated}
                    {...props}
                  />
                </Col>
              </Row>
            )}
            
          </PlanBlocked>
        )}

        {(props.meetingType || []).includes('coming_to_visit') && securityLevelOptions.length > 0 && (
          <Row mb="26px">
            <Col xs>
              <Row>
                <Col xs>
                  <Label>
                    <FormattedMessage {...messages.securityLevel} />
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Col xs>
                  <Field
                    id="securityLevel"
                    name="securityLevel"
                    component={InputSelect}
                    width="100%"
                    options={securityLevelOptions}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <LocationSelectModal
          modalStyle={{
            height: "80%",
            width: "35%",
            minWidth: ["362px", "500px"],
          }}
          itemButtonStyle={{ width: "120px", style: { float: "right" } }}
          isOpen={isOpen}
          locationIdKey="value"
          locationKey="label"
          toggleModal={toggleModal}
          locations={buildings}
          selectedLocation={selectedBuilding}
          isLoading={isLoading}
          modalTitle={intl.formatMessage({ ...messages.modalTitle })}
          itemButtonLabel={intl.formatMessage({
            ...messages.modalSelect,
          })}
          itemDisabledButtonLabel={intl.formatMessage({
            ...messages.modalSelected,
          })}
          footerButtonLabel={intl.formatMessage({
            ...messages.modalButton,
          })}
          footerInfoText={intl.formatMessage({ ...messages.modalFooter })}
          onClickFooterButton={() => {
            setSelectedBuilding(null);
            updateBuildingStorage(null);
            toggleModal();
          }}
          onClickItemButton={(e, item) => {
            setSelectedBuilding(item);
            updateBuildingStorage(item);
            toggleModal();
          }}
          isMobileView={isMobileView}
        />
        <Hr border="none" height="1px" color="#e5e5e5" my="20px" />

        <Button
          disabled={
            pristine ||
            submitting ||
            (!isTemplatePage && !meetingToolAvailable && meetingType === "web_conference")
          }
          onClick={handleSubmit((values) => onSubmit(values))}
          fontWeight="500"
        >
          <FormattedMessage {...messages.save} />
        </Button>
        <div style={{ height: "20px" }} />
      </form>
    </Dashboard>
  );
};

Meetings.propTypes = {};

const MeetingsPage = connect((state) => {
  const selector = formValueSelector("Meetings");
  return {
    meetingType: selector(state, "meetingType"),
    durations: selector(state, "durations"),
    optionalAttendeesNumber: selector(state, "optionalAttendeesNumber"),
    permissions: selector(state, "permissions"),
  };
})(
  reduxForm({
    form: "Meetings",
  })(injectIntl(Meetings))
);

export default MeetingsPage;
