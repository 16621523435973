import React, { useState } from "react";
import { Field } from "redux-form";
import { useParams } from 'react-router';
import styled from "styled-components";
import FontAwesome from "react-fontawesome";
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  Hr,
  ErrorText,
  Label,
  Row,
} from "@d-lighted/design-system";
import SelectionDropdown from "./components/SelectionDropdown";
import ListItemRecord from './components/ListItemRecord';
import { useResources } from '../../../utils/conferenceRoomUtils';
import Tooltip from '../../../components/elements/Tooltip';

const LabelLight = styled(Label)`
  font-size: 14px;
  font-weight: 200;
  top: 3px;
  position: relative;
`;

const LabelWithMargin = styled(Label)`
  margin-right: 8px;
`;

const Text = (props) => {
  const {
    input: { value },
  } = props;
  return <LabelLight primary>{value}</LabelLight>;
};

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const Icon = styled(FontAwesome)`
  color: #e84c3d;
`;

const messages = defineMessages({
  name: {
    id: 'components.form.resourceSelection.name',
    defaultMessage: 'Conference Room Name',
  },
  noBuilding: {
    id: 'components.form.resourceSelection.noOptions.unselected',
    defaultMessage: 'There\'s no building selected. Please select a building first',
  },
  buildingNoRooms: {
    id: 'components.form.resourceSelection.noOptions.noRooms',
    defaultMessage: 'There\'s no conference room found.',
  },
  tooltip: {
    id: 'components.form.resourceSelection.tooltip',
    defaultMessage: 'You can select up to a maximum of 5 rooms. You can make an appointment during the time when one or more of the selected meeting rooms are available. When a room is scheduled, one of the selected rooms will be confirmed (the order of priority will be in the order of selection).',
  },
  addResource: {
    id: 'components.form.resourceSelection.addResource',
    defaultMessage: 'Add Conference Room',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  }
});

const RoomSelection = (props) => {
  const params = useParams();
  const {
    fields,
    meta: { submitFailed, error },
  } = props;
  const [searching, setSearching] = useState(false);
  const [resources, isLoadingRooms, getResources] = useResources(params.uid);

  const checkIfExist = (item) => {
    return fields.getAll().reduce((present, field, index ) => {
      if (present < 0 && field.value === item.value) {
        return index;
      }
      return present;
    }, -1);
  };

  function removeResource(existingIndex) {
    fields.remove(existingIndex);
  }

  const handleAddResources = (item) => {
    const existingIndex = checkIfExist(item);
    if (existingIndex < 0) {
      fields.push(item);
    } else {
      removeResource(existingIndex);
    }
  };

  const handleSelectedComparison = (employee) => {
    for(let i= 0; i < fields.length; ++i) {
      const fieldResource = fields.get(i);
      if(fieldResource.value === employee.value) {
        return true;
      }
    }
  };

  const tooltip = props.intl.formatMessage({...messages.tooltip});
  
  return (
    <>
      <Row>
        <Col xs>
          <Row mt="15px">
            <Col xs={10} mr="-10px">
              <Row>
                <Col xs display="flex">
                  <LabelWithMargin fontSize="13px">
                    <FormattedMessage {...messages.name} />
                  </LabelWithMargin>
                  <Tooltip
                    message={tooltip}
                    width={310}
                    top={-4}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={1}></Col>
          </Row>
          {(fields || []).map((resource, index) => {
            return (
              <Row key={index} mt="15px">
                <Col xs={10} mr="-20px" paddingRight="30px">
                  <Row>
                    <Col xs display="flex" justifyContent="left">
                      <Field name={`${resource}.label`} component={Text} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1}>
                  <ClickableIcon onClick={() => removeResource(index)}>
                    <Icon color="" name="remove" />
                  </ClickableIcon>
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>

      <Hr border="none" height="1px" color="#e5e5e5" mt="15px" />

      {submitFailed && error && <ErrorText>{error}</ErrorText>}

      <Button
        outlined
        mt="15px"
        fontSize="14px"
        fontWeight="normal"
        height="32px"
        onClick={(e) => {
          e.preventDefault();
          getResources(props.building);
          setSearching(!searching);
        }}
      > 
        <FormattedMessage {...messages.addResource} />
      </Button>
      {searching && (
        <SelectionDropdown
          toggle={() => setSearching(!searching)}
          loading={isLoadingRooms}
          handleAdd={handleAddResources}
          headerText={props.intl.formatMessage({...messages.addResource})}
          closeText={props.intl.formatMessage({...messages.close})}
          recordComponent={ListItemRecord}
          comparator={handleSelectedComparison}
          noItemsMessage={<FormattedMessage {...messages.buildingNoRooms} />}
          items={resources}
        />
      )}
    </>
  );
};

export default injectIntl(RoomSelection);
