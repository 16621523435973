import messages from './i18n/validation';
import { useMemo } from 'react';
import { BOOKING_PAGE_URL_VAR } from '../views/home/constants/homeModuleConstants';

export const required = (value) =>
value || typeof value === "number" ? undefined : "入力必須項目です。";

// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const companyEmailRegex = /^([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

const bookingUrlRegex = /^[０-９a-zA-Z0-9_-]+$/i;

// eslint-disable-next-line no-useless-escape
const urlRegex = /^(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:\/~\+#]*[\w\-\@?^=%&amp;\/~\+#])?/i;

const checkedValidation = (message) => {
  return value =>
    value === true ? undefined : message;
};

const conferenceRoomLimitValidation = (message) => {
  return value => {
    if (value == null || value.length <= 5) {
      return undefined;
    }
    return message;
  };
};

const durationsLimitValidation = (message) => {
  return value => {
    if(value == null || value.length <= 0 || value.length > 4) {
      return message;
    }
    return undefined;
  };
};

const emailValidation = (message) => {
  return value => {
    return value && !emailRegex.test(value.toLowerCase())
      ? message
      : undefined;
  };
};

const checkIfEmail = (message) => {
  return value => {
    return value && emailRegex.test(value.toLowerCase())
      ? true
      : false;
  };
};
const bookingUrlValidation = (message) => {
  return value => {
    return value && !bookingUrlRegex.test(value.toLowerCase())
      ? message
      : undefined;
  };
};

const requiredValidation = (message) => {
  return value =>
    value || typeof value === "number" ? undefined : message;
};

const inputLengthValidation = (message) => {
  return value => {
    return value && value.length > 255
      ? message
      : undefined;
  };
};

const passwordLengthValidation = (message) => {
  return value => {
    return value && value.length >= 8
      ? undefined
      : message;
  };
};

const passwordStringValidation = (message) => {
  return value => {
    let matchCount = 0;
    if (value.toUpperCase() !== value) matchCount++;
    if (value.toLowerCase() !== value) matchCount++;
    if (!/^[a-zA-Z0-9]*$/.test(value)) matchCount++;
    if (/\d/.test(value)) matchCount++;
    return matchCount >= 3
      ? undefined
      : message;
  };
};

const blockOfTextLengthValidation = (message) => {
  return value => {
    return value && value.length > 2000
      ? message
      : undefined;
  };
};

const emptyStringValidation = (message) => {
  return value => {
    return value && value.trim() === ""
      ? message
      : undefined;
  };
};

const imageSizeValidation = (message) => {
  return value => {
      // 5MB
      return value && value.length > 5242880
      ? message
      : undefined;
  };
}

const bookingUrlVariableValidation = (message) => {
  return value => {
    if (value) {
      return (value.includes(BOOKING_PAGE_URL_VAR)) ? undefined : message;
    }
    return undefined;
  }
};

const urlValidation = (message) => {
  return value => {
    return value && !urlRegex.test(value.toLowerCase())
      ? message
      : undefined;
  };
};

const availableEventPrefixesLengthValidation = (message) => {
  return value => {
    return value && value.length > 10
      ? message
      : undefined;
  };
};

const privacyCheckedValidation = (message) => {
  return value => {
    if(value) return undefined;
    return message;
  }
}

const httpsUrlValidation = (message) => {
  return value => {
    return value && !/^(ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:\/~\+#]*[\w\-\@?^=%&amp;\/~\+#])?/i.test(value)
      ? message
      : undefined;
  };
}
const phoneNumberValidation = message => {
  return value => {
    return /^\d[\d-]*$/.test(value)
      ? false
      : message ;
  };
};


const companyEmailDomainValidation = message => {
  return value => {
    return value && !companyEmailRegex.test(value) ? message : undefined;
  };
};

const positiveNumberValidation = (message) => {
  return value => {
    const integerValue = parseInt(value, 10) || -1;
    return value && (integerValue < 1)
      ? message
      : undefined;
  };
}

export const useValidation = (intl) => {
  const checked = useMemo(() => checkedValidation(intl.formatMessage({...messages.checked})), [intl]);
  const conferenceRoomLimit = useMemo(() => conferenceRoomLimitValidation(intl.formatMessage({...messages.conferenceRoomLimit})), [intl]);
  const inputLength = useMemo(() => inputLengthValidation(intl.formatMessage({...messages.inputLength})), [intl]);
  const passwordLength = useMemo(() => passwordLengthValidation(intl.formatMessage({...messages.passwordLength})), [intl]);
  const password = useMemo(() => passwordStringValidation(intl.formatMessage({...messages.passwordString})), [intl]);
  const blockOfTextLength = useMemo(() => blockOfTextLengthValidation(intl.formatMessage({...messages.blockOfTextLength})), [intl]);
  const email = useMemo(() => emailValidation(intl.formatMessage({...messages.email})), [intl]);
  const isEmail = useMemo(() => checkIfEmail(intl.formatMessage({...messages.email})), [intl]);
  const bookingUrl = useMemo(() => bookingUrlValidation(intl.formatMessage({...messages.bookingUrl})), [intl]);
  const required = useMemo(() => requiredValidation(intl.formatMessage({...messages.required})), [intl]);
  const emptyString = useMemo(() => emptyStringValidation(intl.formatMessage({...messages.emptyString})), [intl]);
  const bookingUrlVar = useMemo(() => bookingUrlVariableValidation(intl.formatMessage({...messages.bookingUrlVariable})), [intl]);
  const url = useMemo(() => urlValidation(intl.formatMessage({ ...messages.url })), [intl]);
  const durationsLimit = useMemo(() => durationsLimitValidation(intl.formatMessage({...messages.durationsLimit})), [intl]);
  const availableEventPrefixesLength = useMemo(() => availableEventPrefixesLengthValidation(intl.formatMessage({...messages.availableEventPrefixesLength})), [intl]);
  const imageSize = useMemo(() => imageSizeValidation(intl.formatMessage({...messages.imageSizeLength})), [intl]);
  const privacyChecked = useMemo(() => privacyCheckedValidation(intl.formatMessage({...messages.privacy})), [intl]);
  const httpsUrl = useMemo(() => httpsUrlValidation(intl.formatMessage({...messages.httpsUrlRequired})), [intl]);
  const positiveNumber = useMemo(() => positiveNumberValidation(intl.formatMessage({...messages.positiveNumber})), [intl]);
  const phoneNumber = useMemo(() => phoneNumberValidation(intl.formatMessage({...messages.phoneNumberRequired})), [intl]);
  const companyEmailDomain =useMemo(() => companyEmailDomainValidation(intl.formatMessage({...messages.companyEmailDomain})), [intl])
  
  return {
    checked,
    conferenceRoomLimit,
    inputLength,
    blockOfTextLength,
    email,
    isEmail,
    required,
    emptyString,
    bookingUrl,
    bookingUrlVar,
    url,
    availableEventPrefixesLength,
    durationsLimit,
    imageSize,
    passwordLength,
    password,
    privacyChecked,
    httpsUrl,
    positiveNumber,
    phoneNumber,
    companyEmailDomain
  }
};
